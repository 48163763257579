import React, { createContext, useState, useCallback, useLayoutEffect } from "react";
import { consultarProfile } from "../js/apis/perfil/profile";
import MODULOS from "../utils/enumModulos";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [usuario, setUsuario] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);

  const formatarColaboracoesDoUsuario = (colaboracao) => {
    const usuarioPossuiColaboracao = colaboracao.length > 0;

    if (usuarioPossuiColaboracao) {
      return colaboracao.map((colab) => {
        return colab.modulos.includes(MODULOS.ContasAPagar)
          ? {
              ...colab,
              url: `${window.AuthWebSettings.CapWeb}/${colab.cnpj}/compras`,
            }
          : {
              ...colab,
              url: `${window.AuthWebSettings.CarWeb}/${colab.cnpj}/vendas`,
            };
      });
    }

    return colaboracao;
  };

  const consultarPerfilDoUsuario = useCallback(async () => {
    setLoadingProfile(true);
    try {
      const usuario = await consultarProfile();
      if (usuario) {
        setUsuario({...usuario, colaboracao: formatarColaboracoesDoUsuario(usuario.colaboracao)});
      }
    } catch (error) {
    } finally {
      setLoadingProfile(false);
    }
  }, []);

  const obterRotaReferencia = () => {
    let referrerPath = "";

    if(document.referrer) {
      try {
        const referrerUrl = new URL(document.referrer);
        referrerPath = referrerUrl.pathname.replace("/auth", "");
  
        if (!referrerPath.startsWith("/")) {
          referrerPath = `/${referrerPath}`;
        }
      } catch (e) {
        console.error("Invalid referrer URL:", e);
      }
    }
    
    return referrerPath
  }

  const redirecionarUsuarioParaRotaPadrao = useCallback(() => {
    const OperacaoURLPadrao = `${window.AuthWebSettings.OpWeb}/propostas`;

    const cnpjDaUltimaColabSelecionada = localStorage.getItem("cnpjDaUltimaColabSelecionada");

    const ultimaColabSelecionada = usuario.colaboracao.find((colab) => colab.cnpj === cnpjDaUltimaColabSelecionada);

   const rotaReferenciada = obterRotaReferencia();

   const rotaReferenciadaCliente = rotaReferenciada && (rotaReferenciada.includes('contasapagar') || rotaReferenciada.includes('contasareceber'));

    if (usuario.isKob) {
      window.location.href = rotaReferenciada ? rotaReferenciada : OperacaoURLPadrao;
      return;
    }

    if (ultimaColabSelecionada) {
      window.location.href = rotaReferenciadaCliente ? rotaReferenciada : ultimaColabSelecionada.url;
      return;
    }

    window.location.href = rotaReferenciadaCliente ? rotaReferenciada : 'profile'
  }, [usuario])

  const isUsuarioAutenticado = usuario !== null

  useLayoutEffect(() => {
    consultarPerfilDoUsuario();
  }, [consultarPerfilDoUsuario])

  return (
    <AuthContext.Provider
      value={{
        usuario,
        setUsuario,
        consultarPerfilDoUsuario,
        loadingProfile,
        isUsuarioAutenticado,
        redirecionarUsuarioParaRotaPadrao
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };

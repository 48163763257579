import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

// Hook utilizado para acessar os estados globais da aplicação relacionados ao usuário.
export const useUser = () => {
    const context = useContext(AuthContext);

    const {
        usuario,
        setUsuario,
        consultarPerfilDoUsuario,
        loadingProfile,
        isUsuarioAutenticado,
        redirecionarUsuarioParaRotaPadrao,
    } = context;

    if (!context) {
        throw new Error("O hook useUser deve ser usado dentro de um Provider!");
    }

    return {
        usuario,
        setUsuario,
        consultarPerfilDoUsuario,
        loadingProfile,
        isUsuarioAutenticado,
        redirecionarUsuarioParaRotaPadrao
    };
};

import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { useUser } from "./hooks";
import kob_logo from "@koboldbr/react-kob/images/headline.png";
import SplashScreen from "@koboldbr/react-common/components/SplashScreen";

export const PrivateRoute = () => {
  const { isUsuarioAutenticado, loadingProfile } = useUser();

  if (loadingProfile) {
    return <SplashScreen splashImage={kob_logo} />;
  }

  return isUsuarioAutenticado && !loadingProfile ? (
    <Suspense
      fallback={
        <TailSpin color="#0090A1" height={"100px"} wrapperStyle={{ alignItems: "center", justifyContent: "center" }} />
      }
    >
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to="/nao-autenticado" />
  );
};

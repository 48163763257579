//React & Utils
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import kob_logo from "@koboldbr/react-kob/images/headline.png";
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

//Components
import SplashScreen from "@koboldbr/react-common/components/SplashScreen";
import { PrivateRoute } from "./PrivateRoute";
import { NotFound } from "@koboldbr/react-kob/pages/NotFound";
import { ToastContainer } from "react-toastify";
import { NaoAutenticado } from "./pages/NaoAutenticado";
const Profile = lazy(() => import("./pages/Profile"));
const AlterarSenha = lazy(() => import("./pages/AlterarSenha"));
const LoginPage = lazy(() => import("./pages/Login/login"));

const App = () => {
        return (
            <div>
                <Suspense
                    fallback={
                        <SplashScreen
                            splashImage={kob_logo}
                        />
                    }
                >
                    <Routes>
                        <Route path="/Login" element={<LoginPage />} />
                        <Route element={<PrivateRoute />}>
                            <Route path="/Profile" element={<Profile />} />
                            <Route
                                path="/alterar-senha"
                                element={<AlterarSenha />}
                            />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                        <Route
                            path="/Nao-Autenticado"
                            element={<NaoAutenticado />}
                        />
                    </Routes>
                </Suspense>
                <ToastContainer theme="colored" />
            </div>
        );
}

export default App;
